.homepage-main-image {
	padding-top: 60px;

	@include desktop {
		padding-top: 80px;
	}
	
	img {
		border-bottom: 7px solid $color-orange;
		width: 100%;
	}
}