header {
  background-color: $color-white;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 60px;
  position: fixed;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);

  @include tablet {
    height: 80px;
  }
}

.header-container {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .burger {
    width: 30px;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    height: calc(100% - 30px);

    @include tablet {
      padding: 25px;
      height: calc(100% - 50px);
    }

    &:before,
    &:after {
      position: relative;
      display: block;
      content: "";
    }

    &:before,
    &:after,
    .lines {
      top: 0;
      opacity: 1;
      width: 100%;
      height: 3px;
      margin: 3px 0;
      transform: none;
      background: $color-black;
      transition: all 0.3s ease;
    }

    .menu-active & {
      &:before {
        top: 10px;
        transform: rotate(45deg);
      }

      &:after {
        top: -10px;
        transform: rotate(-45deg);
      }

      .lines {
        opacity: 0;
      }
    }
  }

  a img {
    border: 0;
  }
}

.header-logo {
  left: 30px;
  height: 50%;
  margin: 15px auto;
  position: relative;

  @include tablet {
    left: 40px;
    height: 35px;
  }
}
