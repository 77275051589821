section.how-we-reach-kids-section {
	background-color: $color-yellow;
	color: $color-white;
	display: grid;
	position: relative;

	@include tablet {
		display: inline-block;
	}

	.superhero-kid-container {
		display: none;

	    @include desktop {
	    	display: inline-block;
	    	position: absolute;
	    	width: 495px;
	    	left: -220px;
	    	top: -78px;
	    }

		img {
			width: 100%;
		}
	}

	.info-container {
		display: block;
		order: 2;
		text-align: left;

		width: 100%;

		@include tablet {
			display: inline-block;
			text-align: right;
			margin-left: 5%;
			vertical-align: top;
			width: 38%;
		}

		// Ipad 1024x1366
		@media (min-width: 1024px) {
			width: 40%;
		}

		@include desktop {
			width: 40%;
		}

		@media only screen 
		  and (min-device-width: 1366px) 
		  and (max-device-width: 1366px) 
		  and (orientation: landscape) 
		  and (-webkit-min-device-pixel-ratio: 2) {
		  	width: 32%;
		}

		.cloud-container {
			text-align: left;
		}
	}

	.how-we-reach-kids-headline {
		display: block;
		margin: 0 auto;
		order: 1;
		width: 70%;

		@include tablet {
			display: inline-block;
			margin-left: 5%;
			margin-top: 37%;
			width: 37%;
		}

		// Ipad 1024x1366
		@media (min-width: 1024px) {
			margin-top: 15%;
		}

		@include desktop {
			margin-top: 0;
		}

		@media only screen 
		  and (min-device-width: 1366px) 
		  and (max-device-width: 1366px) 
		  and (orientation: landscape) 
		  and (-webkit-min-device-pixel-ratio: 2) {
		  	margin-top: 8%;
		}

		img {
			width: 100%;
		}
	}
}