.page404 {
	text-align: center;
}

.privacy-policy-container,
.terms-of-service-container {
	padding: 30px 5% 0;

	section {
		padding: 0;
		text-align: left;
	}

	@include tablet {
		padding: 80px 5% 0;
	}

	.cta-btn {
		text-align: center;
		width: 100%;
	}
}

.not-found-container {
	padding: 80px 0;
	text-align: center;

	@include tablet {
		padding: 80px;
	}

	img {
		width: 100%;
	}


}