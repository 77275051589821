section.safe-brand-section {
	background-color: $color-green;
	color: $color-white;

	.kids-safe-logo-container {
		display: inline-block;
		text-align: center;
		margin-top: -120px;
		width: 100%;

		img {
			width: 250px;
		}
	}

	.brand-safe-place-headline {
		display: block;
		width: 100%;

		@include tablet {
			display: inline-block;
			margin-top: 27%;
			margin-right: 4%;
			width: 45%;
		}

		@media (min-width: 1024px) {
			margin-top: 5%;
		}

		@include desktop {
			margin-top: 0;
		}

		img {
			width: 100%;
			max-width: 550px;
		}
	}

	.container {
		display: block;
		width: 100%;

		@include tablet {
			display: inline-block;
			text-align: left;
			vertical-align: top;
			width: 45%;
		}

		@include desktop {
			margin-left: 4%;
		}
	}
}