.contact-us-section {
	text-align: center;
	//background-color: lighten($color-yellow, 35%);
	background: url("#{$dir-images}/contact-us/bg.jpg") left center no-repeat;
	background-size: cover;

	h2 {
		color: $color-yellow;
		text-transform: uppercase;
	}
}