.wrapper {
    margin: 0 auto;
    z-index: 0;
}

section {
	padding: 50px 20px;
	text-align: center;
}

.info-container {
    margin: 0 auto;
    max-width: 1500px;
}