@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin button {
  border-radius: 30px;
  color: $color-grey;
  display: inline-block;
  margin: 20px 0;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  width: auto;
  text-transform: uppercase;
  background: darken($color-red, 10%);
  transition: background 0.3s ease;

  &:hover {
    background-color: darken($color-red, 5%);
  }
}

@mixin blue-arrow {
	background-color: $color-light-blue;
	border-radius: 50%;
	border: 1px solid $color-blue;
	box-shadow: 0px 0px 0px 7px #b8d4e6;
	-webkit-box-shadow: 0px 0px 0px 7px #b8d4e6;
	display: inline-block;
	width: 50px;
	height: 50px;	
}