section.sizzle-reel-section {
  background-color: $color-sub-yellow;
  color: $color-white;

  .kids-safe-logo-container {
    display: inline-block;
    text-align: center;
    margin-top: -120px;
    width: 100%;

    img {
      width: 250px;
    }
  }

  .container {
    display: block;
    width: 100%;

    @include tablet {
      display: inline-block;
      text-align: left;
      vertical-align: top;
      width: 45%;
    }

    @include desktop {
      margin-left: 4%;
    }
  }
}
