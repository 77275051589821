body {
  margin: 0;
  color: $color-dark-grey;
  font: 400 16px/24px "Libre Franklin", sans-serif;
  overflow-x: hidden;
  word-break: break-word;
}

h2 {
  font-size: 60px;
  font-weight: 400;
  line-height: 80px;
  text-align: center;
}

h3 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
}

h4 {
  font-size: 30px;
  line-height: 60px;
  font-weight: 400;
}

p {
  font-size: 28px;
  line-height: 40px;
  word-break: break-word;
}

.button {
  @include button;
}

ol,
ul {
  font-size: 20px;
  line-height: 30px;
}