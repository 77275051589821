.totallykidz-advantage-section {
    padding: 20px 0;
    background: darken($color-green, 10%);

    h2 {
        color: $color-white;
        font-size: 60px;

        @include tablet {
            font-size: 80px;
        }

    }

    .info-container {
        padding: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

.advantage-box {
    margin: 20px 1%;
    
    @include tablet {
        width: 30%;
        min-width: 245px;
    }

    @include desktop {
        width: 23%;
    }

    img {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;
        max-width: 320px;
    }

    .advantage-description {
        padding: 10px;
        border-radius: 10px;
        background-color: $color-white;
        box-shadow: 0 -5px 5px rgba(0,0,0,0.1)
    }
}