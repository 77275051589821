section.sales-specialists {
  background-color: $color-sub-green;
  color: $color-white;

  h4 {
    font-weight: bold;
  }

  .sales-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

    @media (max-width: #{$desktop-width}) {
      flex-direction: column;
      gap: 1rem;
    }

    .salesperson {
      flex: 1;
      margin: 0 0.5rem;
      text-align: center;
      padding: 0;

      .title {
        font-size: 16pt;
        font-weight: bold;
        margin-bottom: 10px;
      }

      p {
        font-size: 14pt;
        margin-top: 10px;
        font-weight: 100;
      }

      a {
        border: none;
        font-size: 1rem;
        font-weight: bold;
        font-family: "Libre Franklin";
        text-transform: uppercase;
        cursor: pointer;
        display: inline-block;
        transition: background-color 0.3s ease;

        &:hover {
          opacity: 0.9;
        }
      }

      .btn-sales {
        color: $color-white;
        text-decoration: none;
        //border: 1px solid $color-white;
        //border-radius: 20px;
        //padding: 10px 50px;
        //background-color: transparent;
        //transition: background-color 0.3s ease, color 0.3s ease;
        color: $color-white;
        width: 250px;
        height: 45px;
        background-image: url("../../../images/subdomain/btn-reachout.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
}
