.overlay-header {
	top: 60px;
	width: 100%;
	display: block;
	position: fixed;
	left: 100%;
	height: calc(100vh - 60px);
	transition: left 0.3s ease;

	@include tablet {
		top: 80px;
		height: calc(100vh - 80px);
	}

	.menu-active & {
		left: 0;

		.overlay-background {
			opacity: 1;
		}
	}
}

.overlay-background {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0;
	background: rgba($color-black, 0.25);
}

.overlay-menu {
	top: 0;
	right: 0;
	padding: 40px;
	width: calc(100% - 80px);
	height: calc(100% - 80px);
	background: rgba($color-black, 0.5);
	position: absolute;
	font-size: 30px;
	line-height: 80px;
	text-align: right;
	text-transform: capitalize;

	@include tablet {
		width: auto;
		height: auto;
		min-width: 30%;
		max-width: 550px;
	}

	ul {
		font-size: 30px;
		line-height: 80px;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	a {
		color: $color-white;
		text-decoration: none;
		transition: color 0.3s ease;

		&:hover {
				color: $color-submain;
		}
	}
}