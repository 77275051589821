body {
  margin: 0;
  color: #707070;
  font: 400 16px/24px "Libre Franklin", sans-serif;
  overflow-x: hidden;
  word-break: break-word;
}

h2 {
  font-size: 60px;
  font-weight: 400;
  line-height: 80px;
  text-align: center;
}

h3, .get-in-touch-section h2.get-in-touch {
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
}

h4 {
  font-size: 30px;
  line-height: 60px;
  font-weight: 400;
}

p {
  font-size: 28px;
  line-height: 40px;
  word-break: break-word;
}

.button {
  border-radius: 30px;
  color: #f8f8f8;
  display: inline-block;
  margin: 20px 0;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  width: auto;
  text-transform: uppercase;
  background: rgb(221.1838565022, 69.8475336323, 14.8161434978);
  transition: background 0.3s ease;
}
.button:hover {
  background-color: rgb(239.399103139, 80.0470852018, 22.100896861);
}

ol,
ul {
  font-size: 20px;
  line-height: 30px;
}

header {
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 60px;
  position: fixed;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
@media (min-width: 750px) {
  header {
    height: 80px;
  }
}

.header-container {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.header-container .burger {
  width: 30px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  height: calc(100% - 30px);
}
@media (min-width: 750px) {
  .header-container .burger {
    padding: 25px;
    height: calc(100% - 50px);
  }
}
.header-container .burger:before, .header-container .burger:after {
  position: relative;
  display: block;
  content: "";
}
.header-container .burger:before, .header-container .burger:after,
.header-container .burger .lines {
  top: 0;
  opacity: 1;
  width: 100%;
  height: 3px;
  margin: 3px 0;
  transform: none;
  background: #000;
  transition: all 0.3s ease;
}
.menu-active .header-container .burger:before {
  top: 10px;
  transform: rotate(45deg);
}
.menu-active .header-container .burger:after {
  top: -10px;
  transform: rotate(-45deg);
}
.menu-active .header-container .burger .lines {
  opacity: 0;
}
.header-container a img {
  border: 0;
}

.header-logo {
  left: 30px;
  height: 50%;
  margin: 15px auto;
  position: relative;
}
@media (min-width: 750px) {
  .header-logo {
    left: 40px;
    height: 35px;
  }
}

.wrapper {
  margin: 0 auto;
  z-index: 0;
}

section {
  padding: 50px 20px;
  text-align: center;
}

.info-container {
  margin: 0 auto;
  max-width: 1500px;
}

footer {
  text-align: center;
  background: #f1622e;
}

.footer-container {
  padding: 20px 0 10px;
  background-image: url("../../images/footer/bars-left.svg"), url("../../images/footer/bars-right.svg");
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: auto 100px;
}
@media (min-width: 750px) {
  .footer-container {
    padding: 50px 0 30px;
  }
}

.footer-logo {
  height: 35px;
}

.footer-links {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  margin: 10px auto 0;
  align-items: center;
  width: calc(100% - 20px);
  justify-content: space-around;
}
@media (min-width: 750px) {
  .footer-links {
    font-size: 16px;
    margin: 35px auto 10px;
  }
}
.footer-links a {
  margin: 4px;
  color: #fff;
  text-decoration: none;
}
.footer-links a:hover {
  color: #fff;
}
.footer-links .linkedin-logo {
  height: 20px;
}
@media (min-width: 750px) {
  .footer-links .linkedin-logo {
    height: 30px;
  }
}

.footer-copyright {
  margin: 0 auto;
  font-size: 11px;
  width: calc(100% - 20px);
  color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 750px) {
  .footer-copyright {
    font-size: 14px;
  }
}

.overlay-header {
  top: 60px;
  width: 100%;
  display: block;
  position: fixed;
  left: 100%;
  height: calc(100vh - 60px);
  transition: left 0.3s ease;
}
@media (min-width: 750px) {
  .overlay-header {
    top: 80px;
    height: calc(100vh - 80px);
  }
}
.menu-active .overlay-header {
  left: 0;
}
.menu-active .overlay-header .overlay-background {
  opacity: 1;
}

.overlay-background {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  background: rgba(0, 0, 0, 0.25);
}

.overlay-menu {
  top: 0;
  right: 0;
  padding: 40px;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  font-size: 30px;
  line-height: 80px;
  text-align: right;
  text-transform: capitalize;
}
@media (min-width: 750px) {
  .overlay-menu {
    width: auto;
    height: auto;
    min-width: 30%;
    max-width: 550px;
  }
}
.overlay-menu ul {
  font-size: 30px;
  line-height: 80px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.overlay-menu a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}
.overlay-menu a:hover {
  color: #56b3e5;
}

.homepage-main-image {
  padding-top: 60px;
}
@media (min-width: 1280px) {
  .homepage-main-image {
    padding-top: 80px;
  }
}
.homepage-main-image img {
  border-bottom: 7px solid #fe9300;
  width: 100%;
}

section.where-kids-section h2.headline {
  color: #f1622e;
  font-weight: bold;
}

section.safe-brand-section {
  background-color: #00b48b;
  color: #fff;
}
section.safe-brand-section .kids-safe-logo-container {
  display: inline-block;
  text-align: center;
  margin-top: -120px;
  width: 100%;
}
section.safe-brand-section .kids-safe-logo-container img {
  width: 250px;
}
section.safe-brand-section .brand-safe-place-headline {
  display: block;
  width: 100%;
}
@media (min-width: 750px) {
  section.safe-brand-section .brand-safe-place-headline {
    display: inline-block;
    margin-top: 27%;
    margin-right: 4%;
    width: 45%;
  }
}
@media (min-width: 1024px) {
  section.safe-brand-section .brand-safe-place-headline {
    margin-top: 5%;
  }
}
@media (min-width: 1280px) {
  section.safe-brand-section .brand-safe-place-headline {
    margin-top: 0;
  }
}
section.safe-brand-section .brand-safe-place-headline img {
  width: 100%;
  max-width: 550px;
}
section.safe-brand-section .container {
  display: block;
  width: 100%;
}
@media (min-width: 750px) {
  section.safe-brand-section .container {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    width: 45%;
  }
}
@media (min-width: 1280px) {
  section.safe-brand-section .container {
    margin-left: 4%;
  }
}

section.how-we-reach-kids-section {
  background-color: #fda500;
  color: #fff;
  display: grid;
  position: relative;
}
@media (min-width: 750px) {
  section.how-we-reach-kids-section {
    display: inline-block;
  }
}
section.how-we-reach-kids-section .superhero-kid-container {
  display: none;
}
@media (min-width: 1280px) {
  section.how-we-reach-kids-section .superhero-kid-container {
    display: inline-block;
    position: absolute;
    width: 495px;
    left: -220px;
    top: -78px;
  }
}
section.how-we-reach-kids-section .superhero-kid-container img {
  width: 100%;
}
section.how-we-reach-kids-section .info-container {
  display: block;
  order: 2;
  text-align: left;
  width: 100%;
}
@media (min-width: 750px) {
  section.how-we-reach-kids-section .info-container {
    display: inline-block;
    text-align: right;
    margin-left: 5%;
    vertical-align: top;
    width: 38%;
  }
}
@media (min-width: 1024px) {
  section.how-we-reach-kids-section .info-container {
    width: 40%;
  }
}
@media (min-width: 1280px) {
  section.how-we-reach-kids-section .info-container {
    width: 40%;
  }
}
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  section.how-we-reach-kids-section .info-container {
    width: 32%;
  }
}
section.how-we-reach-kids-section .info-container .cloud-container {
  text-align: left;
}
section.how-we-reach-kids-section .how-we-reach-kids-headline {
  display: block;
  margin: 0 auto;
  order: 1;
  width: 70%;
}
@media (min-width: 750px) {
  section.how-we-reach-kids-section .how-we-reach-kids-headline {
    display: inline-block;
    margin-left: 5%;
    margin-top: 37%;
    width: 37%;
  }
}
@media (min-width: 1024px) {
  section.how-we-reach-kids-section .how-we-reach-kids-headline {
    margin-top: 15%;
  }
}
@media (min-width: 1280px) {
  section.how-we-reach-kids-section .how-we-reach-kids-headline {
    margin-top: 0;
  }
}
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  section.how-we-reach-kids-section .how-we-reach-kids-headline {
    margin-top: 8%;
  }
}
section.how-we-reach-kids-section .how-we-reach-kids-headline img {
  width: 100%;
}

section.our-work-section {
  background-color: #4791c0;
  color: #fff;
  text-align: center;
}
section.our-work-section .container {
  width: 90%;
  margin: 0 auto;
  max-width: 1125px;
}
section.our-work-section .learn-more-btn .button {
  background-color: #fda500;
}

section.our-gaming-partners-section {
  background-color: #fff;
  text-align: center;
}
section.our-gaming-partners-section h2.headline {
  color: #1972ba;
}
section.our-gaming-partners-section .logos {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
}
@media (min-width: 750px) {
  section.our-gaming-partners-section .logos {
    grid-template-columns: 50% 50%;
  }
}
@media (min-width: 1280px) {
  section.our-gaming-partners-section .logos {
    grid-template-columns: 20% 20% 20% 20% 20%;
    min-width: 1280px;
  }
}
section.our-gaming-partners-section .logos .logo {
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
}
section.our-gaming-partners-section .logos .logo:hover {
  transform: scale(1.2);
}
section.our-gaming-partners-section .learn-more-btn {
  pointer-events: none;
}
section.our-gaming-partners-section .learn-more-btn .button {
  border: 1px solid #f1622e;
  background-color: #fff;
  color: #f1622e;
}
section.our-gaming-partners-section .carousel {
  position: relative;
  width: 70%;
  margin: 0 auto;
}
section.our-gaming-partners-section .carousel .bx-wrapper {
  display: inline-block;
}
section.our-gaming-partners-section .carousel .arrow {
  background-color: #e5f5ff;
  border-radius: 50%;
  border: 1px solid #4791c0;
  box-shadow: 0px 0px 0px 7px #b8d4e6;
  -webkit-box-shadow: 0px 0px 0px 7px #b8d4e6;
  display: inline-block;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1;
}
section.our-gaming-partners-section .carousel .left {
  left: -20%;
}
@media (min-width: 750px) {
  section.our-gaming-partners-section .carousel .left {
    left: -15%;
  }
}
section.our-gaming-partners-section .carousel .right {
  right: -20%;
}
@media (min-width: 750px) {
  section.our-gaming-partners-section .carousel .right {
    right: -15%;
  }
}
section.our-gaming-partners-section .carousel .left,
section.our-gaming-partners-section .carousel .right {
  cursor: pointer;
  line-height: 50px;
  top: 20px;
}
@media (min-width: 750px) {
  section.our-gaming-partners-section .carousel .left,
  section.our-gaming-partners-section .carousel .right {
    top: 25px;
  }
}
section.our-gaming-partners-section .carousel .left svg,
section.our-gaming-partners-section .carousel .right svg {
  fill: #4791c0;
  height: 100%;
  width: 100%;
}
section.our-gaming-partners-section .carousel .bx-wrapper {
  box-shadow: unset;
  -webkit-box-shadow: unset;
}
section.our-gaming-partners-section .carousel .logo {
  display: inline-block;
  transition: all 0.2s ease-in-out;
  width: 200px;
}
section.our-gaming-partners-section .carousel .logo:hover {
  transform: translate(0, -20px);
}
section.our-gaming-partners-section .carousel .logo img {
  width: 100%;
}

.totallykidz-advantage-section {
  padding: 20px 0;
  background: rgb(0, 129, 99.6166666667);
}
.totallykidz-advantage-section h2 {
  color: #fff;
  font-size: 60px;
}
@media (min-width: 750px) {
  .totallykidz-advantage-section h2 {
    font-size: 80px;
  }
}
.totallykidz-advantage-section .info-container {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.advantage-box {
  margin: 20px 1%;
}
@media (min-width: 750px) {
  .advantage-box {
    width: 30%;
    min-width: 245px;
  }
}
@media (min-width: 1280px) {
  .advantage-box {
    width: 23%;
  }
}
.advantage-box img {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  max-width: 320px;
}
.advantage-box .advantage-description {
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
}

.contact-us-section {
  text-align: center;
  background: url("../../images/contact-us/bg.jpg") left center no-repeat;
  background-size: cover;
}
.contact-us-section h2 {
  color: #fda500;
  text-transform: uppercase;
}

.get-in-touch-section {
  text-align: center;
  background-color: #f8f8f8;
}
.get-in-touch-section h2.contact {
  color: #1972ba;
}
.get-in-touch-section .info-container {
  display: inline-grid;
  grid-template-columns: 100%;
}
@media (min-width: 750px) {
  .get-in-touch-section .info-container {
    grid-template-columns: 50% 50%;
  }
}
@media (min-width: 1280px) {
  .get-in-touch-section .info-container {
    grid-template-columns: 33% 33% 33%;
    min-width: 1280px;
  }
}
.get-in-touch-section .info-container .info {
  padding: 5%;
  position: relative;
}
.get-in-touch-section .info-container .info:not(:last-of-type):after {
  content: "";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 1px;
  display: block;
  position: absolute;
  background: #707070;
}
@media (min-width: 750px) {
  .get-in-touch-section .info-container .info:not(:last-of-type):after {
    left: 100%;
    width: 1px;
    height: 76%;
    bottom: 12%;
    display: none;
  }
}
@media (min-width: 1280px) {
  .get-in-touch-section .info-container .info:not(:last-of-type):after {
    display: block;
  }
}
.get-in-touch-section .info-container .info:not(:last-of-type):nth-of-type(odd):after {
  display: block;
}
@media (min-width: 750px) {
  .get-in-touch-section .info-container .info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media (min-width: 1280px) {
  .get-in-touch-section .info-container .info {
    padding: 10%;
  }
}
.get-in-touch-section .info-container .info .title {
  color: #f1622e;
}
.get-in-touch-section .info-container .info .description {
  min-height: 48px;
}
.get-in-touch-section .info-container .info .cta-btn {
  margin-top: auto;
}
.get-in-touch-section .info-container .button {
  border-radius: 30px;
  color: #f8f8f8;
  display: inline-block;
  margin: 20px 0;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  width: auto;
  text-transform: uppercase;
  background: rgb(221.1838565022, 69.8475336323, 14.8161434978);
  transition: background 0.3s ease;
}
.get-in-touch-section .info-container .button:hover {
  background-color: rgb(239.399103139, 80.0470852018, 22.100896861);
}

.page404 {
  text-align: center;
}

.privacy-policy-container,
.terms-of-service-container {
  padding: 30px 5% 0;
}
.privacy-policy-container section,
.terms-of-service-container section {
  padding: 0;
  text-align: left;
}
@media (min-width: 750px) {
  .privacy-policy-container,
  .terms-of-service-container {
    padding: 80px 5% 0;
  }
}
.privacy-policy-container .cta-btn,
.terms-of-service-container .cta-btn {
  text-align: center;
  width: 100%;
}

.not-found-container {
  padding: 80px 0;
  text-align: center;
}
@media (min-width: 750px) {
  .not-found-container {
    padding: 80px;
  }
}
.not-found-container img {
  width: 100%;
}

section.sizzle-reel-section {
  background-color: #fda508;
  color: #fff;
}
section.sizzle-reel-section .kids-safe-logo-container {
  display: inline-block;
  text-align: center;
  margin-top: -120px;
  width: 100%;
}
section.sizzle-reel-section .kids-safe-logo-container img {
  width: 250px;
}
section.sizzle-reel-section .container {
  display: block;
  width: 100%;
}
@media (min-width: 750px) {
  section.sizzle-reel-section .container {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    width: 45%;
  }
}
@media (min-width: 1280px) {
  section.sizzle-reel-section .container {
    margin-left: 4%;
  }
}

section.downloads-section .info-container .title {
  color: #fe9300;
}
section.downloads-section .case-studies {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-top: 1rem;
}
@media (max-width: 1280px) {
  section.downloads-section .case-studies {
    flex-direction: column;
    gap: 1rem;
  }
}
section.downloads-section a {
  border: none;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Libre Franklin";
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease;
  text-decoration: none;
}
section.downloads-section a:hover {
  opacity: 0.9;
}
section.downloads-section .btn-media-kit {
  background: url("../../../images/subdomain/btn-media_kit.svg") no-repeat center;
  background-size: cover;
  color: #fff;
  margin-bottom: 2rem;
  height: 60px;
  width: 80%;
}
@media (max-width: 749px) {
  section.downloads-section .btn-media-kit {
    border-radius: 25px;
  }
}
@media (min-width: 750px) {
  section.downloads-section .btn-media-kit {
    border-radius: 25px;
  }
}
@media (min-width: 1280px) {
  section.downloads-section .btn-media-kit {
    width: 812px;
  }
}
section.downloads-section .btn-media-kit:hover {
  filter: brightness(0.8);
}
section.downloads-section .btn-case-studies {
  color: #fff;
  height: 60px;
  width: 80%;
}
@media (min-width: 750px) {
  section.downloads-section .btn-case-studies {
    width: 398px;
  }
}
@media (min-width: 1280px) {
  section.downloads-section .btn-case-studies {
    width: 398px;
  }
}
section.downloads-section .btn-case-studies:hover {
  filter: brightness(0.8);
}
section.downloads-section .btn-girls-case {
  background-image: url("../../../images/subdomain/btn-girls_cs.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
section.downloads-section .btn-boys-case {
  background-image: url("../../../images/subdomain/btn-boys_cs.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
section.downloads-section .btn-kids-case {
  background-image: url("../../../images/subdomain/btn-kids_cs.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

section.sales-specialists {
  background-color: #00b48a;
  color: #fff;
}
section.sales-specialists h4 {
  font-weight: bold;
}
section.sales-specialists .sales-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  section.sales-specialists .sales-container {
    flex-direction: column;
    gap: 1rem;
  }
}
section.sales-specialists .sales-container .salesperson {
  flex: 1;
  margin: 0 0.5rem;
  text-align: center;
  padding: 0;
}
section.sales-specialists .sales-container .salesperson .title {
  font-size: 16pt;
  font-weight: bold;
  margin-bottom: 10px;
}
section.sales-specialists .sales-container .salesperson p {
  font-size: 14pt;
  margin-top: 10px;
  font-weight: 100;
}
section.sales-specialists .sales-container .salesperson a {
  border: none;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Libre Franklin";
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease;
}
section.sales-specialists .sales-container .salesperson a:hover {
  opacity: 0.9;
}
section.sales-specialists .sales-container .salesperson .btn-sales {
  color: #fff;
  text-decoration: none;
  color: #fff;
  width: 250px;
  height: 45px;
  background-image: url("../../../images/subdomain/btn-reachout.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
section.sales-specialists .sales-container .salesperson .btn-sales:hover {
  filter: brightness(0.8);
}