section.our-work-section {
	background-color: $color-blue;
	color: $color-white;
	text-align: center;

	.container {
		width: 90%;
		margin: 0 auto;
		max-width: 1125px;
	}

	.learn-more-btn {

		.button {
			background-color: $color-yellow;	
		}
		

		
	}
}