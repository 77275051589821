.get-in-touch-section {
  text-align: center;
  background-color: $color-grey;

  h2.contact {
    color: $color-main;
  }

  h2.get-in-touch {
    @extend h3;
  }

  .info-container {
    display: inline-grid;
    grid-template-columns: 100%;

    @include tablet {
      grid-template-columns: 50% 50%;
    }

    @include desktop {
      grid-template-columns: 33% 33% 33%;
      min-width: 1280px;
    }

    .info {
      padding: 5%;
      position: relative;

      &:not(:last-of-type) {
        &:after {
          content: "";
          bottom: 0;
          left: 25%;
          width: 50%;
          height: 1px;
          display: block;
          position: absolute;
          background: $color-dark-grey;

          @include tablet {
            left: 100%;
            width: 1px;
            height: 76%;
            bottom: 12%;
            display: none;
          }

          @include desktop {
            display: block;
          }
        }

        &:nth-of-type(odd) {
          &:after {
            display: block;
          }
        }
      }

      @include tablet {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
      }

      @include desktop {
        padding: 10%;
      }

      .title {
        color: $color-red;
      }

      .description {
        min-height: 48px;
      }

      .cta-btn {
        margin-top: auto;
      }
    }

    .button {
      @include button;
    }
  }
}
