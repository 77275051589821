section.downloads-section {
  .info-container {
    .title {
      color: $color-orange;
    }
  }

  .case-studies {
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin-top: 1rem;

    @media (max-width: #{$desktop-width}) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  a {
    border: none;
    font-size: 1rem;
    font-weight: bold;
    font-family: "Libre Franklin";
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s ease;
    text-decoration: none;

    &:hover {
      opacity: 0.9;
    }
  }

  .btn-media-kit {
    background: url("../../../images/subdomain/btn-media_kit.svg") no-repeat
      center;
    background-size: cover;
    color: $color-white;
    margin-bottom: 2rem;
    height: 60px;
    width: 80%;

    @include mobile {
      border-radius: 25px;
    }

    @include tablet {
      border-radius: 25px;
    }

    @include desktop {
      width: 812px;
    }

    &:hover {
      filter: brightness(0.8);
    }
  }

  .btn-case-studies {
    color: $color-white;
    height: 60px;
    width: 80%;

    @include tablet {
      width: 398px;
    }

    @include desktop {
      width: 398px;
    }

    &:hover {
      filter: brightness(0.8);
    }
  }

  .btn-girls-case {
    background-image: url("../../../images/subdomain/btn-girls_cs.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .btn-boys-case {
    background-image: url("../../../images/subdomain/btn-boys_cs.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .btn-kids-case {
    background-image: url("../../../images/subdomain/btn-kids_cs.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
