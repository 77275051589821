footer {
  text-align: center;
  background: $color-red;
}

.footer-container {
  padding: 20px 0 10px;
  background-image: url("../../images/footer/bars-left.svg"),
    url("../../images/footer/bars-right.svg");
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: auto 100px;

  @include tablet {
    padding: 50px 0 30px;
  }
}

.footer-logo {
  height: 35px;
}

.footer-links {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  margin: 10px auto 0;
  align-items: center;
  width: calc(100% - 20px);
  justify-content: space-around;

  @include tablet {
    font-size: 16px;
    margin: 35px auto 10px;
  }

  a {
    margin: 4px;
    color: $color-white;
    text-decoration: none;

    &:hover {
      color: $color-white;
    }
  }

  .linkedin-logo {
    height: 20px;

    @include tablet {
      height: 30px;
    }
  }
}

.footer-copyright {
  margin: 0 auto;
  font-size: 11px;
  width: calc(100% - 20px);
  color: rgba($color-white, 0.5);

  @include tablet {
    font-size: 14px;
  }
}
