section.our-gaming-partners-section {
	background-color: $color-white;
	text-align: center;

	h2.headline {
		color: $color-main;
	}

	.logos {
		display: grid;
		grid-template-columns: 100%;
		width: 100%;

		@include tablet {
			grid-template-columns: 50% 50%;
		}

		@include desktop {
			grid-template-columns: 20% 20% 20% 20% 20%;
			min-width: 1280px;
		}

		.logo {
			margin-bottom: 20px;
			transition: all .2s ease-in-out;

			&:hover {
				transform: scale(1.2);
			}
		}
	}

	.learn-more-btn {
		pointer-events: none;

		.button {
			border: 1px solid $color-red;
			background-color: $color-white;
			color: $color-red;
		}
	}

	.carousel {
		position: relative;
		width: 70%;
		margin: 0 auto;

		.bx-wrapper {
			display: inline-block;
		}

		.arrow {
			@include blue-arrow;
			position: absolute;
			z-index: 1;
		}

		.left {
			left: -20%;

			@include tablet {
				left: -15%;
			}
		}

		.right {
			right: -20%;

			@include tablet {
				right: -15%;
			}
		}

		.left,
		.right {
			cursor: pointer;
			line-height: 50px;
			top: 20px;

			@include tablet {
				top: 25px;
			}

    		svg {
    			fill: $color-blue;
    			height: 100%;
    			width: 100%;
    		}
		}

		.bx-wrapper {
    		box-shadow: unset;
			-webkit-box-shadow: unset;
		}

		.logo {
			display: inline-block;
			transition: all .2s ease-in-out;
			width: 200px;

			&:hover {
				transform: translate(0, -20px);
			}

			img {
				width: 100%;
			}
		}
	}

}